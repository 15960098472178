import './App.css';

function App() {
  return (
    <div className="w-screen h-screen bg-[#f56942] grid place-content-center">
      
      <h1 className='font-bold text-white text-[3em] animate-pulse'>DRYV</h1>

    </div>
  );
}

export default App;
